import React, { memo } from 'react';
import {
  Typography,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar
} from '@mui/material';
import { getProductLink, getPostPrice, parseTitle } from '@utils/index';
import { DealPostType } from '@types';
import MonetizingLink from '@components/MonetizingLink';
import AdminActions from '@components/AdminActions';

const CouponListItem = ({
  latestDeal,
  tag
}: {
  latestDeal: DealPostType;
  tag: string;
}) => {
  return (
    <MonetizingLink
      key={latestDeal.ASIN}
      href={getProductLink(latestDeal.ASIN, tag)}
      target="_blank"
      tag={tag}
      item="amazon-coupon-active-link"
      clickType={getProductLink(latestDeal.ASIN, tag)}
    >
      <ListItem
        sx={{
          display: 'flex',
          '@media (max-width: 540px)': {
            paddingLeft: '0px',
            paddingRight: '0px',
            display: 'block'
          }
        }}
        secondaryAction={
          <>
            <AdminActions ASIN={latestDeal.ASIN} deal={latestDeal} />
          </>
        }
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              width: '80px',
              height: '80px',
              objectFit: 'contain',
              '@media (max-width: 540px)': {
                width: '130px',
                height: '130px',
                margin: '0 auto'
              }
            }}
            src={
              latestDeal.image100Url?.replace('.jpg', '.webp') ||
              latestDeal.image240Url?.replace('.jpg', '.webp') ||
              latestDeal.image600Url?.replace('.jpg', '.webp') ||
              latestDeal.image
            }
            variant="square"
          />
        </ListItemAvatar>
        <ListItemText
          sx={{
            marginLeft: '12px',
            '@media (max-width: 540px)': {
              fontSize: '13px',
              paddingLeft: '0px'
            }
          }}
          primary={
            <MonetizingLink
              key={latestDeal.ASIN}
              href={getProductLink(latestDeal.ASIN, tag)}
              target="_blank"
              underline="always"
              tag={tag}
              item="amazon-coupon-active-link"
              clickType={getProductLink(latestDeal.ASIN, tag)}
            >
              <Typography
                variant="body1"
                component="span"
                sx={{
                  '@media (max-width: 540px)': {
                    fontSize: '13px'
                  }
                }}
              >
                {parseTitle(latestDeal.title)}
              </Typography>
            </MonetizingLink>
          }
          secondary={getPostPrice(latestDeal)}
        />
      </ListItem>
    </MonetizingLink>
  );
};

export default memo(CouponListItem);
