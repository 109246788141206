import React from 'react';
import { Box, Card, Skeleton } from '@mui/material';

const CouponSkeleton = () => {
  return (
    <Box
      sx={{
        width: '100%',
        mb: 2
      }}
    >
      <Card
        sx={{
          height: '240px',
          padding: '1rem'
        }}
      >
        <Skeleton
          sx={{
            marginTop: '4px'
          }}
          variant="rectangular"
          height={30}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            marginTop: '16px'
          }}
          height={30}
        />
        <Box
          sx={{
            display: 'flex',
            marginTop: '10px'
          }}
        >
          <Skeleton
            variant="circular"
            sx={{
              marginTop: '16px',
              // this needs t be 50px x 50px on screens less than 540px
              '@media (max-width: 540px)': {
                width: '50px !important',
                height: '50px !important'
              }
            }}
            height={100}
            width={100}
          />
          <Skeleton
            variant="circular"
            sx={{
              marginTop: '16px',
              marginLeft: '-16px',
              // this needs t be 50px x 50px on screens less than 540px
              '@media (max-width: 540px)': {
                width: '50px !important',
                height: '50px !important'
              }
            }}
            height={100}
            width={100}
          />
          <Skeleton
            variant="circular"
            sx={{
              marginTop: '16px',
              marginLeft: '-16px',
              // this needs to be 50px x 50px on screens less than 540px
              '@media (max-width: 540px)': {
                width: '50px !important',
                height: '50px !important'
              }
            }}
            height={100}
            width={100}
          />
          <Skeleton
            variant="circular"
            sx={{
              marginTop: '16px',
              marginLeft: '-16px',
              // this needs to be 50px x 50px on screens less than 540px
              '@media (max-width: 540px)': {
                width: '50px !important',
                height: '50px !important'
              }
            }}
            height={100}
            width={100}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Skeleton variant="rectangular" width={120} height={28} />
        </Box>
      </Card>
    </Box>
  );
};

export default CouponSkeleton;
